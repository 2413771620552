import { makeVar, ReactiveVar } from '@apollo/client'

const userInitialValue: LumosUser = {
  username: '',
  id: 0,
  authExpiration: 0,
  programExpiresAt: '2099-01-01T12:00:00.000Z',
}

export const currentUserVar: ReactiveVar<LumosUser> = makeVar<LumosUser>(userInitialValue)
