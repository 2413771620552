import { useEffect, useState } from 'react'

import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const cookieTest = (iFrameUri: string, callBack: any) => {
  const messageHandler = (event: MessageEvent) => {
    if (event.origin !== publicRuntimeConfig.oidc.provider) {
      return
    }
    const data = JSON.parse(event.data)
    callBack(data['result'])
    window.removeEventListener('message', messageHandler)
    document.body.removeChild(frame)
  }

  window.addEventListener('message', messageHandler)
  const frame = document.createElement('iframe')
  frame.setAttribute('src', iFrameUri)
  frame.setAttribute('sandbox', 'allow-scripts allow-same-origin')
  frame.setAttribute('style', 'display:none')
  frame.onload = () => {
    frame.contentWindow?.postMessage(JSON.stringify({ test: 'cookie' }), publicRuntimeConfig.oidc.provider)
  }
  document.body.appendChild(frame)
}

const useDetectThirdPartyCookies = (overrideValue?: boolean) => {
  const [thirdPartyCookiesSupported, setThirdPartyCookiesSupported] = useState(overrideValue ?? false)

  useEffect(() => {
    // only needed for test environments with non-lumosity hosts
    if (window.location.hostname.endsWith('lumosity.com')) {
      return setThirdPartyCookiesSupported(true)
    }

    if (overrideValue === undefined) {
      const cookieDetectionURL = `${publicRuntimeConfig.oidc.provider}/session/cookie_support?origin=${window.location.origin}`
      cookieTest(cookieDetectionURL, setThirdPartyCookiesSupported)
    }
  }, [overrideValue])

  return thirdPartyCookiesSupported
}

export default useDetectThirdPartyCookies
