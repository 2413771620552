export const breakpoints = {
  mobileSmall: 320,
  mobile: 390,
  mobileLarge: 576,
  tablet: 768,
  tabletMedium: 850,
  tabletLarge: 1024,
  desktopSmall: 992,
  desktop: 1200,
  desktopLarge: 1440,
}

export const breakpointsBootstrap = {
  sm: breakpoints.mobileLarge,
  md: breakpoints.tablet,
  lg: breakpoints.desktopSmall,
  xl: breakpoints.desktop,
}

export const mediaQuery = {
  maxWidth: {
    mobileSmall: `@media (max-width:${breakpoints.mobileSmall}px)`,
    mobile: `@media (max-width:${breakpoints.mobile}px)`,
    mobileLarge: `@media (max-width:${breakpoints.mobileLarge}px)`,
    tablet: `@media (max-width:${breakpoints.tablet}px)`,
    tabletMedium: `@media (max-width:${breakpoints.tabletMedium}px)`,
    tabletLarge: `@media (max-width:${breakpoints.tabletLarge}px)`,
    desktopSmall: `@media (max-width:${breakpoints.desktopSmall}px)`,
    desktop: `@media (max-width:${breakpoints.desktop}px)`,
    desktopLarge: `@media (max-width:${breakpoints.desktopLarge}px)`,
    bootstrap: {
      sm: `@media (max-width:${breakpointsBootstrap.sm - 0.2}px)`,
      md: `@media (max-width:${breakpointsBootstrap.md - 0.2}px)`,
      lg: `@media (max-width:${breakpointsBootstrap.lg - 0.2}px)`,
      xl: `@media (max-width:${breakpointsBootstrap.xl - 0.2}px)`,
    },
  },
  minWidth: {
    mobileSmall: `@media (min-width:${breakpoints.mobileSmall}px)`,
    mobile: `@media (min-width:${breakpoints.mobile}px)`,
    mobileLarge: `@media (min-width:${breakpoints.mobileLarge}px)`,
    tablet: `@media (min-width:${breakpoints.tablet}px)`,
    tabletMedium: `@media (min-width:${breakpoints.tabletMedium}px)`,
    tabletLarge: `@media (min-width:${breakpoints.tabletLarge}px)`,
    desktopSmall: `@media (min-width:${breakpoints.desktop}px)`,
    desktop: `@media (min-width:${breakpoints.desktop}px)`,
    desktopLarge: `@media (min-width:${breakpoints.desktopLarge}px)`,
  },
  bootstrap: {
    sm: `@media (min-width:${breakpointsBootstrap.sm}px)`,
    md: `@media (min-width:${breakpointsBootstrap.md}px)`,
    lg: `@media (min-width:${breakpointsBootstrap.lg}px)`,
    xl: `@media (min-width:${breakpointsBootstrap.xl}px)`,
  },
}
