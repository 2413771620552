import React, { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import type { AmplitudeClient } from 'amplitude-js'

import { PlayContext } from '~/constants'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import useInitializeAnalytics from '~/hooks/analytics/useInitializeAnalytics'

// Amplitude event tracking context

export enum AnalyticsEvent {
  ApplicationOpen = 'Application Open',
  DemographicsStart = 'Demographics Start',
  DemographicsFinish = 'Demographics Finish',
  CTAClick = 'CTA Click',
  FitTestStart = 'Fit Test Start',
  FitTestFinish = 'Fit Test Finish',
  GameStart = 'Game Start',
  GameAbort = 'Game Abort',
  GameFinish = 'Game Finish',
  PopUpView = 'Pop Up View',
  ReferralSource = 'Referral Source',
  // ScreenView = 'Screen View', No longer used, instead it is a combination of the screen_name and category
  WorkoutGameSwap = 'Workout Game Swap',
  WorkoutFinish = 'Workout Finish',
  WorkoutStart = 'Workout Start',
}

export enum AnalyticsCategory {
  Acquisition = 'acquisition',
  Demographics = 'demographics',
  FitTest = 'fit_test',
  Navigation = 'navigation',
  Authentication = 'authentication',
  Workout = 'workout',
  Games = 'games',
  Settings = 'settings',
  Purchase = 'purchase',
}

export enum ScreenName {
  Author = 'author',
  Checkout = 'checkout',
  Landing = 'landing',
  Login = 'login_frontend',
  OnboardStart = 'onboard_start',
  Gender = 'gender',
  Education = 'education',
  Profession = 'profession',
  Referral = 'referral',
  FitTestStart = 'fit_test_start',
  Pregame = 'pregame',
  PlanSelection = 'plan_selection',
  Percentile = 'percentile', // Fit Test Percentile screen
  Science = 'science', // Fit Test Science Behind screen
  Streak = 'streak',
  TrainingReminders = 'training_reminders',
  Games = 'games',
  Home = 'home',
  Stats = 'stats',
}

export type OptionalAmplitude = AmplitudeClient | null

export const getCategoryFromContext = (playContext: PlayContext) => {
  switch (playContext) {
    case PlayContext.Workout:
      return AnalyticsCategory.Workout
    case PlayContext.FreePlay:
      return AnalyticsCategory.Games
    case PlayContext.FitTest:
      return AnalyticsCategory.FitTest
    default:
      return AnalyticsCategory.Games
  }
}

interface AnalyticsContextStorage {
  client: OptionalAmplitude
  currentScreen: string
  lastCtaClicked: string
  setCurrentScreen: (name: string) => unknown
  setlastCtaClicked: (name: string) => unknown
  utmCampaignId: string
  utmCampaignSource: string
  utmCampaignMedium: string
  referrer: string
}

export const AnalyticsContext = React.createContext<AnalyticsContextStorage>({
  client: null,
  currentScreen: '',
  lastCtaClicked: '',
  setCurrentScreen: (_name: string) => null,
  setlastCtaClicked: (_name: string) => null,
  utmCampaignId: '',
  utmCampaignSource: '',
  utmCampaignMedium: '',
  referrer: '',
})

const AnalyticsProvider: React.FunctionComponent = ({ children }) => {
  const [currentScreen, setCurrentScreen] = React.useState<string>('')
  const [lastCtaClicked, setlastCtaClicked] = React.useState<string>('')
  const currentUser = useReactiveVar(currentUserVar)
  const { amplitudeClient, utmCampaignId, utmCampaignSource, utmCampaignMedium, referrer } = useInitializeAnalytics()

  useEffect(() => {
    const userId = currentUser?.id
    amplitudeClient?.setUserId(userId ? String(userId) : null)
  }, [amplitudeClient, currentUser])

  return (
    <AnalyticsContext.Provider
      value={{
        client: amplitudeClient,
        currentScreen,
        lastCtaClicked,
        setCurrentScreen,
        setlastCtaClicked,
        utmCampaignId,
        utmCampaignSource,
        utmCampaignMedium,
        referrer,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
