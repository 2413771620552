import { ReactiveVar } from '@apollo/client'

import { GameListFilter } from '~/constants'
import makeVarPersisted from '~/utils/makeVarPersisted'

export const showSideNavVar: ReactiveVar<boolean> = makeVarPersisted<boolean>(true, 'show_side_nav')
export const playGameSoundsVar: ReactiveVar<boolean> = makeVarPersisted<boolean>(true, 'game_sound_on')
export const gameListFilterVar: ReactiveVar<GameListFilter> = makeVarPersisted<GameListFilter>(
  GameListFilter.byArea,
  'game_list_filter',
)

export const originPathBeforeSubscribeVar: ReactiveVar<string> = makeVarPersisted<string>(
  '',
  'origin_path_before_subscribe',
)
