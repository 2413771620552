import { makeVar, ReactiveVar } from '@apollo/client'

/**
 * Due to NextJS SSR, persisted reactive variables must be initialized on the client!
 * Example:
 *   useEffect(() => {
 *     const parsed = JSON.parse(localStorage.getItem(STORAGE_NAME) ?? 'null')
 *     parsed && YOUR_REACTIVE_VARIABLE(parsed)
 *   })
 */
const makeVarPersisted = <T>(initialValue: T, storageName: string): ReactiveVar<T> => {
  const value = initialValue

  // Create a reactive var with initial value
  const reactiveVariable = makeVar<T>(value)

  const onNextChange = (newValue: T | undefined) => {
    try {
      // Try to add the value to local storage
      if (newValue === undefined) {
        localStorage.removeItem(storageName)
      } else {
        localStorage.setItem(storageName, JSON.stringify(newValue))
      }
    } catch {
      // ignore
    }

    // Re-register for the next change
    reactiveVariable.onNextChange(onNextChange)
  }

  // Register for the first change
  reactiveVariable.onNextChange(onNextChange)

  return reactiveVariable
}

export default makeVarPersisted
