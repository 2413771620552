import styled from 'styled-components'
import { BaseModalBackground } from 'styled-react-modal'

export const modalTransitionDuration = 150

const StyledModalBackground = styled(BaseModalBackground)<{ opacity: number }>`
  background: rgba(0, 0, 0, 0.71);
  backdrop-filter: ${({ opacity = 1 }) => `blur(${8 * opacity}px)`};
  /* ensure this element is in front of all app content */
  z-index: 10000;
  opacity: ${({ opacity = 1 }) => opacity};
  transition: all ${modalTransitionDuration}ms ease-in;
`

export default StyledModalBackground
