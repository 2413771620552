import { useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'

import { PUBLIC_PATHS } from '~/constants'
import { currentUserVar } from '~/reactive-vars'

export interface RouteGuardProps {
  children: JSX.Element
}

const RouteGuard = ({ children }: RouteGuardProps): JSX.Element => {
  const currentUser = useReactiveVar(currentUserVar)
  const router = useRouter()
  const currentPath = router.pathname
  const [isAuthorized, setIsAuthorized] = useState(false)
  const { query } = router
  const queryString = new URLSearchParams(query as Record<string, string>).toString()

  useEffect(() => {
    // does not perform the hook when currentUserVar is in its initial state
    if (currentUser && currentUser.authExpiration === 0) return

    if ((currentUser && currentUser.authExpiration >= Date.now()) || PUBLIC_PATHS.includes(currentPath)) {
      setIsAuthorized(true)
    } else {
      setIsAuthorized(false)

      /* 
        To ensure that marketing campaign query params are picked up by AnalyticsContext, 
        pass along that query string to the login page. Without this, the query params will be undefined
        and the ability to track a user journey that begins with a marketing campaign is lost.
      */
      let redirectURL = '/login'
      if (queryString.length) {
        redirectURL += `?${queryString}`
      }

      if (redirectURL !== currentPath) {
        router.push(redirectURL)
      }
    }
  }, [currentUser, currentPath, router, queryString])

  if (!isAuthorized) {
    return <></>
  }
  return children
}

export default RouteGuard
