import { useCallback, useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import type { ContentCards, Card } from '@braze/web-sdk'
import getConfig from 'next/config'

import type { MarketingContext } from '~/context/MarketingContext'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'

const { publicRuntimeConfig } = getConfig()

const defaultLogger = () => false

// Used for Braze tracking
export const useInitializeMarketingFramework = () => {
  const BRAZE_SDK_KEY = publicRuntimeConfig.marketing.sdkKey
  const BRAZE_SDK_ENDPOINT = publicRuntimeConfig.marketing.endpoint
  const ENABLE_BRAZE_LOGGING = publicRuntimeConfig.marketing.enableLogging

  const currentUser = useReactiveVar(currentUserVar)
  const [marketingUser, setMarketingUser] = useState<MarketingContext['marketingUser']>(null)
  const [logCustomMarketingEvent, setLogCustomMarketingEvent] = useState<MarketingContext['logCustomMarketingEvent']>(
    () => defaultLogger,
  )
  const [logCardClickEvent, setLogCardClickEvent] = useState<MarketingContext['logCardClickEvent']>(() => true)
  const [logCardDismissalEvent, setLogCardDismissalEvent] = useState<MarketingContext['logCardDismissalEvent']>(
    () => true,
  )
  const [contentCards, setContentCards] = useState<Card[]>([])

  const initializeMarketingFramework = useCallback(
    async (userId) => {
      if (process.env.NODE_ENV === 'development' && !BRAZE_SDK_KEY) {
        console.log('No BRAZE_SDK_KEY detected. Braze will not initialize.')
        return
      }
      // dynamically import required methods on client https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#ssr
      // uses Webpack magic comments https://webpack.js.org/api/module-methods/#magic-comments
      const {
        initialize,
        openSession,
        automaticallyShowInAppMessages,
        changeUser,
        getUser,
        logCustomEvent,
        logCardClick,
        logCardDismissal,
        requestContentCardsRefresh,
        subscribeToContentCardsUpdates,
        getCachedContentCards,
      } = await import(
        /* webpackExports: ["initialize", "openSession", "automaticallyShowInAppMessages", "changeUser", "getUser", "logCustomEvent", "logCardClick", "logCardDismissal", "requestContentCardsRefresh", "subscribeToContentCardsUpdates", "getCachedContentCards"] */
        '@braze/web-sdk'
      )
      initialize(BRAZE_SDK_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
        enableLogging: ENABLE_BRAZE_LOGGING,
        allowUserSuppliedJavascript: true,
      })
      // enable in-app messages - must be called before `openSession()`
      automaticallyShowInAppMessages()

      // track content card updates
      subscribeToContentCardsUpdates((cards: ContentCards) => {
        console.info('Received content cards from Braze...', cards)
        setContentCards(cards.cards)
      })
      // eagerly set content cards to an initial value
      const cards: ContentCards = getCachedContentCards()
      setContentCards(cards.cards)

      // set the braze user with the L2 ID from the auth JWT
      changeUser(userId)

      const user = getUser()

      // set reference to braze event dispatch function
      setLogCustomMarketingEvent(() => logCustomEvent)
      // set brazeUser -- necessary for setting attributes
      if (user) {
        setMarketingUser(user)
      }

      requestContentCardsRefresh()

      setLogCardClickEvent(() => (card: Card, forContentCards?: boolean) => {
        console.info('Braze card clicked', card)
        return logCardClick(card, forContentCards)
      })
      setLogCardDismissalEvent(() => (card: Card) => {
        console.info('Braze card dismissed', card)
        return logCardDismissal(card)
      })

      openSession()
    },
    [BRAZE_SDK_ENDPOINT, BRAZE_SDK_KEY, ENABLE_BRAZE_LOGGING],
  )

  useEffect(() => {
    if (currentUser?.id) {
      initializeMarketingFramework(currentUser.id)
    }
  }, [currentUser, initializeMarketingFramework])

  return {
    marketingUser,
    logCustomMarketingEvent,
    logCardClickEvent,
    logCardDismissalEvent,
    contentCards,
    setContentCards,
  }
}
