import { useState, useEffect, useRef } from 'react'

import { useReactiveVar } from '@apollo/client'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'

import { currentUserVar } from '~/graphql/reactive-vars'

const { publicRuntimeConfig } = getConfig()

type LDProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => JSX.Element

export const FeaturesProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const currentUser = useReactiveVar(currentUserVar)
  const userId = currentUser?.id
  const providerRef = useRef<LDProvider | null>(null)
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    const setup = async () => {
      if (publicRuntimeConfig.features.offline) {
        // Feature flags are in offline mode. Will retrieve features from features file
        return
      }
      // Feature flags are in online mode. Will retrieve features from LD, falling back to features file
      const { clientSideID } = publicRuntimeConfig.features
      if (!clientSideID) {
        throw new Error('LAUNCH_DARKLY_CLIENT_SIDE_ID must be provided.')
      }
      const user = {
        kind: 'user',
        key: String(userId) ?? 'unknown',
      }
      const component = await asyncWithLDProvider({
        clientSideID,
        user,
        options: {
          streaming: true,
        },
      })
      providerRef.current = component
      setIsReady(true)
    }
    setup()
  }, [userId, isReady])

  const Provider = providerRef.current
  if (!publicRuntimeConfig.features.offline && Provider) {
    return <Provider>{children}</Provider>
  } else {
    return <>{children}</>
  }
}
