import { useRef } from 'react'

import { useRouter } from 'next/router'

import { AnalyticsEvent } from '~/context/AnalyticsContext'
import type { OptionalAmplitude } from '~/context/AnalyticsContext'

export enum MarketingCampaignStorageItem {
  currentCampaign = 'currentCampaign',
  lastCampaign = 'lastCampaign',
}

const CAMPAIGN_VALIDITY = 300 * 1000 // campaign data TTL - not valid after 5 minutes -- MAY NEED CALIBRATION WITH PRODUCT

/**
 * Records an event when the app is launched via a marketing campaign URL. An initial event is fired
 * to record the initial launch of the app via the marketing campaign, and the campaign details
 * will be available to all subsequent events in a session. LocalStorage is used to persist these
 * campaign properties when a logged out user must make a round trip to LumAuth.
 * @param amplitudeClient - Amplitude instance that provides a `logEvent` method
 * @returns - Object containing utmCampaignId, utmCampaignSource, and utmCampaignMedium
 */
export const useTrackMarketingCampaign = (amplitudeClient: OptionalAmplitude) => {
  const {
    query: { utm_campaign, utm_source, utm_medium, referrer },
    route,
  } = useRouter()
  const utmCampaignIdRef = useRef<string>(utm_campaign as string)
  const utmCampaignSourceRef = useRef<string>(utm_source as string)
  const utmCampaignMediumRef = useRef<string>(utm_medium as string)
  const referrerRef = useRef<string>(referrer as string)
  const campaignEntryEventSentRef = useRef<boolean>(false) // use a ref flag to ensure event is sent only once per session

  const {
    utmCampaignId: persistedCampaignId,
    utmCampaignSource: persistedCampaignSource,
    utmCampaignMedium: persistedCampaignMedium,
    expiry: persistedCampaignExpiry,
    referrer: persistedReferrer,
  } = JSON.parse(localStorage.getItem(MarketingCampaignStorageItem.lastCampaign) ?? '{}')

  // if an unexpired persisted campaign is present due to a round trip to LumAuth
  if ((persistedCampaignId || persistedReferrer) && persistedCampaignExpiry < Date.now()) {
    // store campaign details in refs because this session began via that campaign
    utmCampaignIdRef.current = persistedCampaignId
    utmCampaignSourceRef.current = persistedCampaignSource
    utmCampaignMediumRef.current = persistedCampaignMedium
    referrerRef.current = persistedReferrer
  }

  const isNewSession = amplitudeClient?.isNewSession()
  const hasArrivedViaAdReferral = !!(utm_campaign || utm_source || utm_medium)
  const hasArrivedViaOrganicReferral = !hasArrivedViaAdReferral && !!referrer
  const shouldSendReferralEvent =
    !campaignEntryEventSentRef.current && isNewSession && (hasArrivedViaAdReferral || hasArrivedViaOrganicReferral)

  // if query params include campaign details or referrer
  if (shouldSendReferralEvent) {
    // send event to record referral
    amplitudeClient?.logEvent(AnalyticsEvent.ReferralSource, {
      utm_campaign,
      utm_source,
      utm_medium,
      referrer,
      page_location: route,
    })
    campaignEntryEventSentRef.current = true

    // persist campaign in anticipation of potential round trip to LumAuth
    const campaignDataToStore = {
      utmCampaignId: utm_campaign,
      utmCampaignSource: utm_source,
      utmCampaignMedium: utm_medium,
      referrer,
      expiry: Date.now() + CAMPAIGN_VALIDITY,
    }

    localStorage.setItem(MarketingCampaignStorageItem.lastCampaign, JSON.stringify(campaignDataToStore))
  }

  return {
    utmCampaignId: utmCampaignIdRef.current,
    utmCampaignSource: utmCampaignSourceRef.current,
    utmCampaignMedium: utmCampaignMediumRef.current,
    referrer: referrerRef.current,
  }
}
