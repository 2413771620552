import { makeVar, ReactiveVar } from '@apollo/client'

import GamesLibrary, { GamesLibraryDictionary } from '~/gameConfigs/GamesLibrary'

export const gamesArrayInitialValue: GameConfig[] = GamesLibrary
export const gamesDictionaryInitialValue: Record<string, GameConfig> = GamesLibraryDictionary

export const gameConfigsArray: ReactiveVar<GameConfig[]> = makeVar<GameConfig[]>(gamesArrayInitialValue)
export const gameConfigsDictionary: ReactiveVar<Record<string, GameConfig>> =
  makeVar<Record<string, GameConfig>>(gamesDictionaryInitialValue)
