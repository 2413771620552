import { SnackbarProvider as OriginalSnackbarProvider } from 'notistack'
import styled from 'styled-components'

import BottomLeavesImage from '~/images/Snackbars/bottom_leaves.svg'
import BubblesImage from '~/images/Snackbars/bubbles.svg'
import TopLeavesImage from '~/images/Snackbars/top_leaves.svg'

interface SnackbarProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <OriginalSnackbarProvider maxSnack={1}>
      {/* Load image for use by snackbar components via <use> tag */}
      <TopLeaves />
      <BottomLeaves />
      <Bubbles />
      {children}
    </OriginalSnackbarProvider>
  )
}

export default SnackbarProvider

const TopLeaves = styled(TopLeavesImage).attrs({
  title: 'top-leaves',
  id: 'top-leaves',
})`
  /* Hide image */
  width: 0;
  height: 0;
  position: absolute;
`

const BottomLeaves = styled(BottomLeavesImage).attrs({
  title: 'bottom-leaves',
  id: 'bottom-leaves',
})`
  /* Hide image */
  width: 0;
  height: 0;
  position: absolute;
`

const Bubbles = styled(BubblesImage).attrs({
  title: 'bubbles',
  id: 'bubbles',
})`
  /* Hide image */
  width: 0;
  height: 0;
  position: absolute;
`
