import { useRef } from 'react'

import amplitude from 'amplitude-js'
import getConfig from 'next/config'

import { OptionalAmplitude } from '~/context/AnalyticsContext'
import useTrackApplicationOpen from '~/hooks/analytics/useTrackApplicationOpen'
import { useTrackMarketingCampaign } from '~/hooks/analytics/useTrackMarketingCampaign'
const { publicRuntimeConfig } = getConfig()

const useInitializeAnalytics = () => {
  const AMPLITUDE_API_KEY = publicRuntimeConfig.analytics?.amplitude?.apikey
  const amplitudeClientRef = useRef<OptionalAmplitude>(null)

  if (AMPLITUDE_API_KEY && amplitudeClientRef.current === null) {
    // initialize Amplitude if API key is defined and the instance has not already been initialized
    const instance = amplitude.getInstance()
    instance.init(AMPLITUDE_API_KEY, '', {
      includeUtm: true,
      includeReferrer: true,
    })

    // in development, log new session for visibility
    if (process.env.NODE_ENV === 'development') {
      instance.onNewSessionStart((client) =>
        console.log(`~~~~~~ NEW AMPLITUDE SESSION BEGUN (DEV): SESSION ID: ${client.getSessionId()} ~~~~~~`),
      )
    }
    amplitudeClientRef.current = instance
  }

  // HOOKS HERE MUST FOLLOW AMPLITUDE INITIALIZATION (useTrackMarketingCampaign + useTrackApplicationOpen)
  const { utmCampaignId, utmCampaignSource, utmCampaignMedium, referrer } = useTrackMarketingCampaign(
    amplitudeClientRef.current,
  )
  useTrackApplicationOpen(amplitudeClientRef)

  return {
    utmCampaignId,
    utmCampaignSource,
    utmCampaignMedium,
    referrer,
    amplitudeClient: amplitudeClientRef.current,
  }
}

export default useInitializeAnalytics
