import { MutableRefObject, useRef } from 'react'

import { useRouter } from 'next/router'

import { AnalyticsEvent, OptionalAmplitude } from '~/context/AnalyticsContext'

const APPLICATION_SESSION_VALIDITY = 300 * 1000 // 5 minutes
const APPLICATION_LAST_OPENED_AT = 'applicationLastOpenedAt'

const useTrackApplicationOpen = (amplitudeClientRef: MutableRefObject<OptionalAmplitude>) => {
  const applicationOpenEventSentRef = useRef<boolean>(false)
  const { route } = useRouter()

  const applicationLastOpenedAt = localStorage.getItem(APPLICATION_LAST_OPENED_AT)
  const isNewSession = applicationLastOpenedAt === null || +applicationLastOpenedAt < Date.now()

  if (!applicationOpenEventSentRef.current) {
    if (isNewSession) {
      amplitudeClientRef.current?.logEvent(AnalyticsEvent.ApplicationOpen, {
        category: 'application',
        page_location: route,
      })
    }
    applicationOpenEventSentRef.current = true
    localStorage.setItem(APPLICATION_LAST_OPENED_AT, JSON.stringify(Date.now() + APPLICATION_SESSION_VALIDITY))
  }
}

export default useTrackApplicationOpen
